var jQuery = require('jquery');
var _ = require('underscore');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.navigation = (function ($, _) {
    "use strict";

    /**
     * Activate footer
     */
    var activateFooter = function () {

        window.footerOpen = false;
        var body = document.querySelector("body"),
            footer = document.querySelector("footer");

        if (footer !== null) {
            // Private Functions:
            // ------------------
            var closeTalkToSomeone = function () {
                $("#talk-to-someone").fadeOut(260).removeClass("expanded");
                $(".js-need-to-talk-toggle").removeClass("active");
            };

            $(document).on("click", function (event) {
                if (!$(event.target).closest("#talk-to-someone").length) {
                    closeTalkToSomeone();
                }
            });

            // Add click handlers
            $(document).on("click", ".js-need-to-talk-toggle", function (event) {
                event.preventDefault();

                if (!AG.utilities.isMobile()) {
                    if ($(this).hasClass("active")) {
                        closeTalkToSomeone();
                    } else {
                        $("#talk-to-someone").fadeIn(260).addClass("expanded");
                        $(".js-need-to-talk-toggle").addClass("active");
                    }
                }

                if (AG.utilities.isMobile()) {
                    $("nav.primary").hide();
                    $('body').css("overflow", "hidden");
                    $(".talk-to-someone-mobile").animate({
                        "padding-top": "80px"
                    }).addClass("expanded");
                }

                return false;
            });

            // Close Talk to Someone div
            $(".talk-to-someone-mobile .mobile-close").on("click", function () {
                $(".menu-item-container .menu").removeClass("slideMenuOut");
                $(".main-nav-container").removeClass("slideLeftwards");
                $("nav.primary").show();
                $('body').css("overflow", "visible");

                $(".talk-to-someone-mobile").css({
                    "padding-top": "0px"
                }).removeClass("expanded");
            });

            $(".footer-header").on("click", function () {
                var specialcaseFooterHeader = $(this).parent().hasClass("contact-us");
                if ((AG.utilities.isMobile() && !specialcaseFooterHeader)) {
                    if ($(this).parent().hasClass("active")) {
                        $(this).next().slideUp(300);
                    } else {
                        $(this).next().slideDown(300);
                    }
                    $(this).parent().toggleClass("active");
                }
            });

            // Country Selector (moved to activateMenu function)
            // if (!$('.selected-country').data('init')) {
            //     $('selected-country').data('init', true);
            //     $(document).on("click", ".selected-country", function () {
            //         $(".js-country-selection-notice").fadeIn(250);
            //         $("body").addClass("no-scrolling");
            //     });
            // }


            // this seems like old functionality that's no longer used
            // $(".footer-icon").on("click", function () {
            //     $(this).parent().parent().toggleClass("expanded");
            //     window.footerOpen = !window.footerOpen;
            // });

            // $(document).bind('keydown', function (event) {
            //     /*  Key Codes:
            //             Down: 40
            //             Up: 38
            //             Space: 32
            //     */
            //     var footerOpen = window.footerOpen || false;

            //     if ((event.keyCode === 32 || event.keyCode === 40) && !footerOpen && window.lastSectionActive) {
            //         $(".footer-icon").trigger("click");
            //     }
            //     if ((event.keyCode === 38) && footerOpen) {
            //         $(".footer-icon").trigger("click");
            //     }
            // });

            // $(document).bind('mousewheel DOMMouseScroll', function (event) {
            //     var footerOpen = window.footerOpen || false;

            //     function getMouseWheelDelta() {
            //         if (event.originalEvent.wheelDelta) {
            //             return event.originalEvent.wheelDelta;
            //         } else if (event.originalEvent.deltaY) {
            //             return event.originalEvent.deltaY;
            //         }
            //         return event.originalEvent.detail * -40;
            //     }

            //     var delta = getMouseWheelDelta();
            //     if (delta <= -120 && window.lastSectionActive && !footerOpen) {
            //         // Open the footer
            //         $(".footer-icon").trigger("click");
            //     } else if (delta >= 120 && footerOpen) {
            //         // Close the footer
            //         $(".footer-icon").trigger("click");
            //     }
            // });
        }
    };

    /**
     * Activate clicking on the main menu links
     */
    var activateMenu = function () {

        // ensure only inited once
        if ($('nav.primary').data('init')) return;
        $('nav.primary').data('init', true);

        var $menuItemContainer = $(".menu-item-container"),
            $menu = $menuItemContainer.find("> .menu:first"),
            $menuHeaders = $menuItemContainer.find("> .menu > li > .item.title"),
            $body = $("body");


        // Country Selector (for both desktop and mobile versions)
        $(".selected-country").on("click", function () {
            $(".js-country-selection-notice").fadeIn(250);
            $("body").addClass("no-scrolling");
        });

        var activeSubMenu = function () {
            return $menuItemContainer.find("> .menu > li.active > .column");
        };

        var collapseSubMenu = function () {
            if (!AG.utilities.isMobile()) {
                var $activeSubMenu = activeSubMenu();
                if ($activeSubMenu.length !== 0) {
                    $activeSubMenu
                        .hide()
                        .off('wheel.navscroll touchmove.navscroll');
                }
            }
        };

        $(document).on('click', function (event) {
            if (!$(event.target).closest("nav.primary").length) {
                collapseSubMenu();
            }
        });

        $("nav.primary a").on('click', function () {
            collapseSubMenu();
        });

        $(".menu-item-container a").on('click', function () {
            if (AG.utilities.isMobile()) {
                $body.removeClass("mobile-menu-open");
                $menu.removeClass("slideMenuIn").addClass("slideMenuOut");
            }

        });

        // Mobile event for color of menu links
        if ($body.hasClass("mobile-menu-open")) {
            var chevronAnchor = $('.mobile-chevron a');
            chevronAnchor.on('touchstart', function () {
                $(this).addClass('tapped');
            });
            chevronAnchor.on('touchend', function () {
                $(this).removeClass('tapped');
            });
        }

        $(".hamburger").on("click", function () {
            if ($menuItemContainer.hasClass("active")) {
                $menuItemContainer.removeClass("active");
                $body.removeClass("mobile-menu-open");
                $menu.removeClass("slideMenuIn").addClass("slideMenuOut");
            } else {
                $menuItemContainer.addClass("active");
                $menu.removeClass("slideMenuOut").addClass("slideMenuIn");
                $body.addClass("mobile-menu-open");
                $(".column, .sub.menu .item.title + ul").hide();
                $(".menu > li").removeClass("active");
            }
        });

        $(document).off('click.outsidemainnav').on("click.outsidemainnav", function (event) {
            if (!$(event.target).closest("nav.primary").length) {
                var isOpen = $body.hasClass("mobile-menu-open");
                $menuItemContainer.removeClass("active");
                if (isOpen) {
                    $body.removeClass("mobile-menu-open");
                    $menu.removeClass("slideMenuIn").addClass("slideMenuOut");
                } else {
                    $menuItemContainer.find(".active").removeClass("active");
                }
            }
        });

        $("nav.primary").on("mouseleave", function () {
            if (!AG.utilities.isMobile()) {
                collapseSubMenu();
                $menuItemContainer.find(".active").removeClass("active");
                $menuItemContainer.removeClass("active");
                $body.removeClass("mobile-menu-open");
                $menu.removeClass("slideMenuIn slideMenuOut");
            }
        });

        /*
         * This function sets up the selection behaviour for the primary menu items
         */
        $menuHeaders.on("click mouseenter", function () {
            if (!$body.hasClass("mobile-menu-open")) {
                var $closestLi = $(this).closest("li");
                if (!$closestLi.hasClass("active")) {
                    // Find the currently active header and turn it off
                    $.each($menuHeaders, function () {
                        var parent = $(this).parent();
                        if ($closestLi !== $(this)) {
                            collapseSubMenu();
                            parent.removeClass("active");
                        }
                    });
                    // Activate this one
                    $closestLi.addClass("active");
                    var $column = $closestLi.find(".column");
                    if ($column.length) {

                        var height = $column.height() + 75;
                        $column.animate({
                            'marginTop': '-=' + height + 'px'
                        }, 0);
                        $column.show();
                        $column.animate({
                            'marginTop': '+=' + height + 'px'
                        }, 500);

                        // if nav dropdown is scrollable, don't let wheel event bubble down   
                        if (AG.utilities.isOverflown($column)) {
                            $column.on('wheel.navscroll touchmove.navscroll', function (e) {
                                e.stopPropagation();
                            });
                        }
                    }
                }
            }
        });

        /*
         * This function sets up the hide behaviour for the primary menu items when the mouse leaves the sub menu
         */
        $menuItemContainer.find("> .menu > li > .column").on("mouseleave", function () {
            collapseSubMenu();
        });

        $(document).off('click.mainmenuitem').on("click.mainmenuitem", ".mobile-menu-open .menu > li > .item.title", function () {
            var $closestLi = $(this).closest("li");
            var wasActive = $closestLi.hasClass("active");
            var $menuContainer = $(".mobile-menu-open .menu:first");

            // Reset all back to defaults
            $(".menu > li").removeClass("active");
            $(".mobile-menu-open .column").slideUp(250);
            $(".sub.menu .item.title + ul").slideUp(250);

            if (wasActive) {
                $closestLi.find(".column").slideUp(250);
            } else {
                // Activate this one
                $closestLi.addClass("active");
                $closestLi.find(".column").slideDown(250);
                $closestLi.find(".sub.menu").removeClass("active");

                $menuContainer.animate({
                    scrollTop: 0
                }, 300);
            }
        });

        $(".sub.menu .item.title").on("click", function () {
            if (AG.utilities.isMobile()) {
                $(this).closest(".sub.menu").toggleClass("active");
                $(this).next("ul").slideToggle(250);
            }
        });

        var $element = $("nav.primary");
        if (!$element.length) {
            return true;
        }
        var elHeight = 0,
            elTop = 0,
            $document = $(document),
            dHeight = 0,
            $window = $(window),
            wHeight = 0,
            wScrollCurrent = 0,
            wScrollBefore = 0,
            wScrollDiff = 0;

        $window.on('scroll', function () {
            elHeight = $element.outerHeight();
            dHeight = $document.height();
            wHeight = $window.height();
            wScrollCurrent = $window.scrollTop();
            wScrollDiff = wScrollBefore - wScrollCurrent;
            elTop = parseInt($element.css('top')) + wScrollDiff;

            if (wScrollCurrent <= 0) {
                // scrolled to the very top; element sticks to the top
                $element.css('top', 0);
            } else if (wScrollDiff > 0) {
                // scrolled up; element slides in
                $element.css('top', elTop > 0 ? 0 : elTop);
            } else if (wScrollDiff < 0) // scrolled down
            {
                if (wScrollCurrent + wHeight >= dHeight - elHeight) {
                    // scrolled to the very bottom; element slides in
                    // $element.css('top', (elTop = wScrollCurrent + wHeight - dHeight) < 0 ? elTop : 0);
                } else { // scrolled down; element slides out
                    $element.css('top', Math.abs(elTop) > elHeight ? -elHeight : elTop);
                }
            }

            wScrollBefore = wScrollCurrent;
        });
    };

    /**
     * Carousel Menu
     */
    var initCarouselMenu = function ($carouselMenu, breakpointsParam, arrowsParam, callback) {
        if ($carouselMenu === undefined) {
            throw "Make sure to pass in a jQuery selection to the initCarouselMenu()";
        }

        var $slides = $carouselMenu.find(".menu-carousel__item"),
            $activeSlide = $carouselMenu.find(".menu-carousel__item.is-active"),
            slideIndex = $slides.index($activeSlide),
            numberOfSlides = $slides.length,
            firstLoad = true;

        var breakpoints = breakpointsParam || {
            "1600": {
                numSlides: Math.min(5, numberOfSlides),
                slidesToScroll: 3
            },
            "1200": {
                numSlides: Math.min(4, numberOfSlides),
                slidesToScroll: 3
            },
            "992": {
                numSlides: Math.min(3, numberOfSlides),
                slidesToScroll: 2
            },
            "768": {
                numSlides: Math.min(2, numberOfSlides),
                slidesToScroll: 1
            },
            "540": {
                numSlides: 2,
                slidesToScroll: 1
            },
            "mobile": {
                numSlides: 1,
                slidesToScroll: 1
            }
        };


        var arrows = arrowsParam || {
            "prev": '<i class="agi agi-f-chevron-left"></i>',
            "next": '<i class="agi agi-f-chevron-right"></i>'
        }

        $carouselMenu.on('init', function (event, slick) {            
            // return current tab ID in callback on slide load
            if (callback) {
                setTimeout(function () {
                    var $linkedSlides = $(".js-linked-slides");
                    var currentTab = $linkedSlides.find('.js-linked-slide.is-active').data('slide-id');
                    callback(currentTab, 'load');
                }, 100);
            }
        });


        var carouselMenu = $carouselMenu.slick({
            infinite: false,
            speed: 300,
            mobileFirst: true,
            swipeToSlide: true,
            touchThreshold: 5,
            slidesToShow: breakpoints["mobile"].numSlides,
            slidesToScroll: breakpoints["mobile"].slidesToScroll,
            arrows: true,
            nextArrow: '<span class="menu-carousel__nav menu-carousel__nav--next">' + arrows["next"] + '</span>',
            prevArrow: '<span class="menu-carousel__nav menu-carousel__nav--prev">' + arrows["prev"] + '</span>',
            initialSlide: Math.max(0, (slideIndex - breakpoints["mobile"].numSlides + 1)),
            responsive: [{
                breakpoint: 539,
                settings: {
                    slidesToShow: breakpoints["540"].numSlides,
                    slidesToScroll: breakpoints["540"].slidesToScroll,
                    initialSlide: Math.max(0, (slideIndex - breakpoints["540"].numSlides + 1))
                }
            }, {
                breakpoint: 767,
                settings: {
                    slidesToShow: breakpoints["768"].numSlides,
                    slidesToScroll: breakpoints["768"].slidesToScroll,
                    initialSlide: Math.max(0, (slideIndex - breakpoints["768"].numSlides + 1))
                }
            }, {
                breakpoint: 991,
                settings: {
                    slidesToShow: breakpoints["992"].numSlides,
                    slidesToScroll: breakpoints["992"].slidesToScroll,
                    initialSlide: Math.max(0, (slideIndex - breakpoints["992"].numSlides + 1))
                }
            }, {
                breakpoint: 1199,
                settings: {
                    slidesToShow: breakpoints["1200"].numSlides,
                    slidesToScroll: breakpoints["1200"].slidesToScroll,
                    initialSlide: Math.max(0, (slideIndex - breakpoints["1200"].numSlides + 1))
                }
            },
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: breakpoints["1600"].numSlides,
                    slidesToScroll: breakpoints["1600"].slidesToScroll,
                    initialSlide: Math.max(0, (slideIndex - breakpoints["1600"].numSlides + 1))
                }
            }]
        });

        $carouselMenu.on('setPosition', function (event, slick) {
            var $slickSlides = slick.$slides,
                tallestSlide = null,
                minHeight = 0;

            $slickSlides.css("min-height", $slickSlides.css("min-height"));
            tallestSlide = _.max($slickSlides, function (slide) {
                return $(slide).outerHeight();
            });

            minHeight = $(tallestSlide).outerHeight();
            $slickSlides.css("min-height", minHeight);

            if (slick.$nextArrow !== null && !slick.$nextArrow.hasClass("slick-hidden")) {
                slick.$slider.addClass("menu-carousel--has-arrows");
            } else {
                slick.$slider.removeClass("menu-carousel--has-arrows");
            }
            if (firstLoad) carouselMenu.slick('resize');
        });

        $carouselMenu.on("click", ".js-carousel-item", function () {
            var $clickedItem = $(this);
            _activateSlide($clickedItem);
        });

        $carouselMenu.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var $slickSlides = slick.$slides;
            if (AG.utilities.isSmallerThan(480)) {
                setTimeout(function () {
                    _activateSlide($($slickSlides[nextSlide]));
                }, 500);
            }
        });

        var _activateSlide = function ($slide) {
            var slick = carouselMenu.slick("getSlick"),
                showSlide = $slide.data("slide-id"),
                $linkedSlides = $(".js-linked-slides");

            if (!$slide.hasClass("is-active")) {
                slick.$slides.removeClass("is-active");
                $slide.addClass("is-active");

                if (showSlide !== undefined) {
                    $linkedSlides.find(".js-linked-slide").removeClass("is-active");
                    $linkedSlides.find(".js-linked-slide[data-slide-id=" + showSlide + "]").addClass("is-active");
                    _updateHash(showSlide);

                    // return current tab ID in callback on slide change
                    if (callback) {
                        var currentTab = $linkedSlides.find('.js-linked-slide.is-active').data('slide-id');
                        callback(currentTab, 'change');
                    }

                } else if ($slide[0].href !== undefined) {
                    setTimeout(function () {
                        // Did this because doing "$slide.click()" would create an infinite loop and stack overflow error.
                        window.location = $slide[0].href;
                    }, 200);
                }
            }
        };

        var _updateHash = function (tab, accordion) {

            if (arguments.length) {

                // if there's no current tab specified (i.e. no existing hash), go find it
                if(tab === ''){
                    tab = $carouselMenu.find('.js-carousel-item.is-active').data('page-id');
                }

                var newHash = tab;
                if (accordion) {
                    newHash = newHash + "," + accordion;
                }
                window.location.hash = newHash;
            }
            else {

                var slick = carouselMenu.slick("getSlick"),
                    currentHashArr = decodeURIComponent(window.location.hash).replace(/#/g, "").split(","),
                    currentTab = currentHashArr[0],
                    currentAccordion = currentHashArr[1],
                    $linkedSlides = $(".js-linked-slides");


                // if tab currently defined in hash
                if (currentTab.length > 0) {

                    if (currentAccordion !== undefined) {

                        var accordionId = "#" + currentAccordion;
                        $(accordionId).collapse("show");
                        if (firstLoad) {
                            setTimeout(function () {
                                AG.utilities.scrollToElement($(accordionId).parent());
                            }, 100);
                        }
                    }



                    if ($linkedSlides.length !== 0) {
                        var $theNextActiveSlide = slick.$slider.find(".js-carousel-item[data-slide-id=" + currentTab + "]"),
                            nextSlideIndex = $theNextActiveSlide.data("slick-index") + 1;

                        slick.$slides.removeClass("is-active");
                        $theNextActiveSlide.addClass("is-active");                   

                        // if target carousel tab is hidden, scroll to it
                        if( $theNextActiveSlide.attr('aria-hidden') === "true" ){
                            slick.slickGoTo(Math.max(0, (nextSlideIndex - slick.options.slidesToShow)), false);
                        }

                        $linkedSlides.find(".js-linked-slide").removeClass("is-active");
                        $linkedSlides.find(".js-linked-slide[data-slide-id=" + currentTab + "]").addClass("is-active");

                    }



                    firstLoad = false;

                    // else if hash not present
                } else {

                    currentTab = $linkedSlides.find('.js-linked-slide.is-active').data('slide-id');

                    if (currentTab) {

                        // update hash with initial active item (without triggering a hashchange event)
                        history.replaceState('init', null, '#' + currentTab);

                        // return current tab ID                        
                        // if(callback) callback(currentTab );
                        // console.log('hash not present (on load)');

                    }


                }
            }
        };

        /* We're using the BS Accordions */
        $(".js-linked-accordion").on("show.bs.collapse", function (e) {            

            var currentHashArr = decodeURIComponent(window.location.hash).replace(/#/g, "").split(","),
                currentTab = currentHashArr[0],
                accordionId = $(this).attr("id");

            _updateHash(currentTab, accordionId);
            
        });



        $(".js-linked-accordion").on("hide.bs.collapse", function (e) {

            // prevent event from acting on incorrectly triggered parent collapse hide events
            if (!$(this).is(e.target)) {                       
                return;
            }

            var currentHashArr = decodeURIComponent(window.location.hash).replace(/#/g, "").split(","),
                currentTab = currentHashArr[0],
                currentAccordion = currentHashArr[1],
                accordionId = $(this).attr("id");

            if (currentAccordion === accordionId) {
                _updateHash(currentTab);
            }
            else {
                _updateHash(currentTab, currentAccordion);
            }            
        });

    


        // listen for clicks on links within accordion panels that target on-page accordion panels
        // and scroll 'em
        $(".js-linked-accordion").on('click', 'a[href]', function (e) { 
  
            var link = $(this).attr('href');         

            // exit if link is not on same page
            if(!link.includes(window.location.pathname)) return;

            // exit if no hash value within link
            var linkHashString = link.split('#')[1];
            if(!linkHashString) return; 

            // exit if no secondary hash value
            var linkHashArr = linkHashString.split(",");
            if(!linkHashArr[1]) return; 
            
            // exit if target element doesn't exist
            var tar = '#' + linkHashArr[1];    
            if($(tar).length < 1) return;            

            // wait for target to be visible before scrolling
            var interval = setInterval(function () {
                if ($(tar).is(':visible')) {
                    clearInterval(interval);
                    AG.utilities.scrollToElement($(tar).parent(), 20);
                }
            }, 50);

        });



        $(window).on("hashchange", function () {
            _updateHash();                      
        });
        _updateHash();   
    };

    var anchorScroll = function () {

        // from query string value
        var element = AG.utilities.getQueryVariable('scrollto');

        if ($('#' + element).length) {
            // scroll to element with 20px offset
            AG.utilities.scrollToElement('#' + element, 20);
        }

        // from on page link
        $(document).on('click', '[data-nav="scrollto"]', function (e) {
            e.preventDefault();
            var $this = $(this);
            AG.utilities.scrollToElement($this.attr('href'), $this.data('offset'), $this.data('speed'));
        });

    }

    /**
     * Tab Menu
     */
    var initTabMenu = function ($tabMenu, callback) {


        $tabMenu.on("click", ".js-tabmenu-item", function () {
            var $clickedItem = $(this);
            _activateSlide($clickedItem);
        });

        var _activateSlide = function ($slide) {
            var showSlide = $slide.data("slide-id");

            if (!$slide.hasClass("is-active")) {
                if (showSlide !== undefined) {
                    _updateHash(showSlide);
                }
            }
        };

        var _updateHash = function (tab) {

            // if new hash passed
            if (arguments.length) {
                var newHash = tab;
                window.location.hash = newHash;
            }

            // else no hash passed
            else {
                var currentHashArr = decodeURIComponent(window.location.hash).replace(/#/g, ""),
                    currentTab = currentHashArr.split('?')[0], // trim any params
                    $linkedSlides = $(".js-linked-tabs");

                // if hash present in URL
                if (currentTab.length > 0) {

                    // return current tab ID
                    if (callback) callback(currentTab);


                    if ($linkedSlides.length !== 0) {
                        $tabMenu.find(".js-tabmenu-item").removeClass("is-active");
                        $tabMenu.find(".js-tabmenu-item[data-slide-id=" + currentTab + "]").addClass("is-active");

                        $linkedSlides.find(".js-linked-tab").removeClass("is-active");
                        $linkedSlides.find(".js-linked-tab[data-slide-id=" + currentTab + "]").addClass("is-active");
                    }


                    // else if hash not present
                } else {

                    currentTab = $linkedSlides.find('.js-linked-tab.is-active').data('slide-id');

                    if (currentTab) {

                        // update hash with initial active item (without triggering a hashchange event)
                        history.replaceState('init', null, '#' + currentTab);

                        // return current tab ID
                        if (callback) callback(currentTab);

                    }


                }
            }
        };

        $(window).on("hashchange", function () {
            _updateHash();
        });
        _updateHash();

    }

    return {
        activateFooter: activateFooter,
        activateMenu: activateMenu,
        initCarouselMenu: initCarouselMenu,
        initTabMenu: initTabMenu,
        activateAnchorScroll: anchorScroll
    };

}(jQuery, _));
